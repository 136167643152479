import httpUtil from "@/utils/httpUtil";

/**字典 */
export const getByDictType = async params => httpUtil.post("/api/crmPc/sysDict/getByDictType", params);

/**社团会员类型 */
export const getAssociationMemberLevelList = params => httpUtil.post("/api/crmPc/association/getAssociationMemberLevelList", params);

/**添加修改社团会员类型 */
export const setAssociationMemberLevel = params => httpUtil.post("/api/crmPc/association/setAssociationMemberLevel", params);

/**添加社团会员 */
export const addAssociationMember = params => httpUtil.post("/api/crmPc/association/addAssociationMember", params);

/**修改社团会员 */
export const updateAssociationMember = params => httpUtil.post("/api/crmPc/association/updateAssociationMember", params);

/**获取社团会员列表 */
export const getAssociationMemberList = params => httpUtil.post("/api/crmPc/association/getAssociationMemberList", params);

/**修改社团成员 */
export const setAssociationEmployee = params => httpUtil.post("/api/crmPc/association/setAssociationEmployee", params);

/**获取社团成员 */
export const getAssociationEmployeeList = params => httpUtil.post("/api/crmPc/association/getAssociationEmployeeList", params);

/**移除社团成员 */
export const delAssociationEmployee = params => httpUtil.post("/api/crmPc/association/delAssociationEmployee", params);

/**获得关联园区社团的商品信息 */
export const getGoodsListByLink = params => httpUtil.post("/api/crmPc/member/getGoodsListByLink", params);

/**获得商品会员关联列表 */
export const getGoodsMemberLinkList = params => httpUtil.post("/api/crmPc/member/getGoodsMemberLinkList", params);

/**关联商品和会员 */
export const linkGoodsAndMember = params => httpUtil.post("/api/crmPc/member/linkGoodsAndMember", params);

/**删除商品和会员关联 */
export const delGoodsMemberLink = params => httpUtil.post("/api/crmPc/member/delGoodsMemberLink", params);

/**会员职务关联分销等级 */
export const setAssociationMemberLevelLink = params => httpUtil.post("/api/crmPc/association/setAssociationMemberLevelLink", params);