<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column prop="memberNo" align="center" label="会员编号" show-overflow-tooltip />
                <el-table-column prop="companyName" align="center" label="会员名称" show-overflow-tooltip />
                <el-table-column prop="levelName" align="center" label="会员类型" show-overflow-tooltip />
                <el-table-column prop="industryName" align="center" label="行业名称" show-overflow-tooltip />
                <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column prop="endDate" align="center" label="到期时间" show-overflow-tooltip />
                <el-table-column prop="memberType" align="center" label="会员类型" width="80" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.memberType ? '个人' : '公司' }}
                    </template>
                </el-table-column>
                <el-table-column prop="memberStatusName" align="center" label="状态" width="80" show-overflow-tooltip >
                    <template slot-scope="scope">
                        <el-tag>{{ scope.row.memberStatusName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button class="mr10" @click="showUserTableModel(scope.row)" type="text">成员</el-button>
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                            v-if="scope.row.memberStatus === 0" @confirm="disableFun(scope.row, 2)" icon="el-icon-info"
                            icon-color="red" title="确定要禁用这条会员？">
                            <el-button slot="reference" style="color: #F56C6C" type="text">禁用</el-button>
                        </customPopconfirm>
                        <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                            v-if="scope.row.memberStatus === 2" @confirm="disableFun(scope.row, 0)" icon="el-icon-info"
                            title="确定要启用这条会员？">
                            <el-button slot="reference" type="text">启用</el-button>
                        </customPopconfirm>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="450px" center>
            <el-form :model="formData" ref="formData" label-width="78px">
                <el-form-item label="公司名称" prop="memberName">
                    <el-input v-model="showFormData.memberName" disabled placeholder="公司名称"></el-input>
                </el-form-item>
                <el-form-item label="会员名称" prop="companyName">
                    <el-input v-model="showFormData.companyName" disabled placeholder="会员名称"></el-input>
                </el-form-item>
                <el-form-item label="会员属性" prop="levelSort">
                    <el-radio-group v-model="formData.memberType">
                        <el-radio-button v-for="(name, id) in memberTypeData" :key="id" :label="id">{{ name }}
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="会员类型" prop="memberLevel">
                    <el-select v-model="formData.memberLevel" placeholder="请选择类型">
                        <el-option v-for="(name, id) in memberLevel" :key="id" :label="name" :value="String(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm" v-loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="入团成员" :visible.sync="showUserTable"
            :destroy-on-close="true" width="80%" center>
            <commonTable :tableData="userTableData" :loading="userLoading" @handleSizeChange="userHandleSizeChange"
                @handleCurrentChange="userHandleCurrentChange" :currentPage="userCurrentPage" :total="userTotal">
                <template v-slot:table>
                    <el-table-column prop="name" align="center" label="姓名" show-overflow-tooltip />
                    <el-table-column prop="levelName" align="center" label="职务" show-overflow-tooltip />
                    <el-table-column prop="personLabelName" align="center" label="标签" show-overflow-tooltip />
                    <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                    <el-table-column prop="createTime" align="center" label="入团时间" show-overflow-tooltip />
                    <el-table-column prop="createTime" align="center" label="到期时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.endDate?scope.row.endDate:"永久" }}
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="companyName" align="center" label="所在单位" show-overflow-tooltip />
                    <el-table-column prop="industryName" align="center" label="所属行业" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" class="mr10" @click="updateJobFun(scope.row)">编辑</el-button>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="delUser(scope.row)"
                                icon="el-icon-info" icon-color="red" title="确定要移除这个成员？">
                                <el-button slot="reference" style="color: #F56C6C" type="text">移除</el-button>
                            </customPopconfirm>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="修改成员职务" :visible.sync="showUpdateJob"
            :destroy-on-close="true" width="350px" center>
            <el-form :model="formDataUser" ref="formDataUser" label-width="78px">
                <el-form-item label="成员职务" prop="memberLevel">
                    <el-select v-model="formDataUser.memberJob" placeholder="请选择职务">
                        <el-option v-for="(name, id) in memberJob" :key="id" :label="name" :value="String(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="成员标签" prop="personLabel">
                    <el-select v-model="formDataUser.personLabel" multiple placeholder="请选择标签">
                        <el-option v-for="(row, index) in personLabel" :key="index" :label="row.dictLabel"
                            :value="String(row.dictValue)">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showUpdateJob = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdateJob" v-loading="jobBtnLoading">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
    getByDictType,

    getAssociationMemberLevelList,
    updateAssociationMember,
    getAssociationMemberList,

    setAssociationEmployee,
    getAssociationEmployeeList,
    delAssociationEmployee,
} from "@/api/associationMember";
import customPopconfirm from "@/components/common/customPopconfirm";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //成员列表
            userCurrentPage: 1, //当前页
            userPageSize: 10, //显示条数
            userLoading: false, //表格加载
            userTotal: 0, //总条数
            userShowModel: false,
            userTableData: [],
            memberJob: {},
            showUserTable: false,
            memberId: 0,
            //模态框配置
            modelTitle: "",
            showFormData: {
                memberName: "",
                companyName: ""
            },
            formData: {
                memberId: 0,
                memberType: 0,
                memberLevel: "",
            },
            formType: "",
            associationId: 0,
            memberLevel: {},
            memberTypeData: { 0: '企业', 1: '个人' },
            memberStatusData: { 0: "正常", 1: "过期", 2: "禁用" },
            auditStatusData: { 0: "待审核", 1: "审核通过", 2: "审核不通过" },
            btnLoading: false,
            //职务修改配置
            showUpdateJob: false,
            formDataUser: {
                employeeId: 0,
                memberJob: "",
                personLabel: []
            },
            jobBtnLoading: false,
            personLabel: [],
        };
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId
            this.currentPage = 1
            this.getAssociationMemberLevelList();
            this.getList();
        }
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    created() {
        let associationId = JSON.parse(sessionStorage.getItem("checkedAssociation")).associationId;
        this.associationId = associationId;
        this.getAssociationMemberLevelList();
        this.getList();
    },
    methods: {
        /*成员配置 */
        /**@method 编辑职务 */
        updateJobFun(data) {
            this.showUpdateJob = true;
            this.formDataUser = {
                employeeId: data.employeeId,
                memberJob: data.memberJob ? data.memberJob : "",
                personLabel: data.personLabel ? data.personLabel.split(",") : []
            };
        },
        /**@method 提交编辑职务 */
        submitUpdateJob() {
            this.jobBtnLoading = true;
            let params = {
                ...this.formDataUser
            };
            params.personLabel = this.formDataUser.personLabel.join(",")
            setAssociationEmployee(params).then(res => {
                this.jobBtnLoading = false;
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "修改成功"
                    })
                    this.showUpdateJob = false;
                    this.getUserList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.jobBtnLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 显示成员列表 */
        showUserTableModel(row) {
            this.showUserTable = true;
            this.memberId = row.memberId;
            this.getUserList();
        },
        /**@method 获取成员数据 */
        async getUserList() {
            let params = {
                pageSize: this.userPageSize,
                pageNum: this.userCurrentPage,
                memberId: this.memberId
            };
            try {
                this.userLoading = true;
                let res = await getAssociationEmployeeList(params)
                this.userLoading = false;

                const { data } = res;
                let userTableData = [];
                for (let row of data.pageInfo.list) {
                    if (row.personLabelList) {
                        let personLabelName = [];
                        for (let child of row.personLabelList) {
                            personLabelName.push(child.dictLabel);
                        }
                        row.personLabelName = personLabelName.join("、")
                    }
                    userTableData.push(row);
                }
                this.userTableData = userTableData;
                this.userTotal = data.pageInfo.total;
            } catch (error) {
                this.userLoading = false;
                this.userTableData = [];
            }
        },
        /**@method 切换行 */
        userHandleSizeChange(val) {
            this.pageSize = val;
            this.getUserList();
        },
        /**@method 切换下一页 */
        userHandleCurrentChange(val) {
            this.currentPage = val;
            this.getUserList();
        },
        /**@method 移除成员 */
        delUser(row) {
            delAssociationEmployee({ employeeId: row.employeeId }).then(res => {
                if (res.code === 200) {
                    this.getUserList();
                } else {
                    this.$message.error(err.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /*成员配置 */
        /**@method 获取字典数据 */
        getAssociationMemberLevelList() {
            getAssociationMemberLevelList({ associationId: this.associationId, levelType: 0 }).then(res => {
                if (res.code === 200) {
                    let memberLevel = {};
                    for (let row of res.data) {
                        memberLevel[row.memberLevel] = row.levelName;
                    }
                    this.memberLevel = memberLevel;
                }
            })
            getAssociationMemberLevelList({ associationId: this.associationId, levelType: 1 }).then(res => {
                if (res.code === 200) {
                    let memberJob = {};
                    for (let row of res.data) {
                        memberJob[row.memberLevel] = row.levelName;
                    }
                    this.memberJob = memberJob;
                }
            })
            getByDictType({
                dictType: "person_label"
            }).then(res => {
                if (res.code === 200) {
                    this.personLabel = res.data.sysDictDataList;
                }
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                associationId: this.associationId,
                showPass: 0
            };
            try {
                this.loading = true;
                let res = await getAssociationMemberList(params)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.memberStatusName = this.memberStatusData[row.memberStatus]
                    tableData.push(row);
                }
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 编辑会员
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑会员";
            this.formType = "update";
            this.showFormData = {
                memberName: row.name,
                companyName: row.companyName
            };
            this.formData = {
                memberId: row.memberId,
                memberType: row.memberType,
                memberLevel: row.memberLevel,
            };
        },
        /**@method 提交编辑会员 */
        submitForm() {
            this.btnLoading = true;
            updateAssociationMember(this.formData).then(res => {
                this.btnLoading = false;
                if (res.code === 200) {
                    this.$message({
                        message: "编辑成功",
                        type: "success"
                    })
                    this.getList();
                    this.showModel = false;
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.btnLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        disableFun(row, status) {
            updateAssociationMember({
                memberId: row.memberId,
                memberStatus: status
            }).then(res => {
                if (res.code === 200) {
                    this.getList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>